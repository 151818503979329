html,
body,
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scroll-behavior: smooth;
}

:target {
  scroll-margin-top: 12rem;
}

html {
  font-size: 62.5% !important;
}
